import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import wallet from './wallet.png';
import Back from '../Back';
import "./pump.scss";
import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react';
import fon from "./fon.png";
import EarningsComponent from './EarningsComponent/EarningsComponent';
import { getPotentialUsdtSection, getPumpTasks } from '../../http';
import finger from "./finger.png";
import pointer from "./pointer.png";
import icon1 from "./icon1.png"
import icon2 from "./icon2.png"
import visa from "./visa.png"
import tether from "./tether.png"
import { openLink } from '../../Utils/telegramUtils';
import { formatNumber } from '../../Utils/formatnumber';
import BuyCoin from '../BuyCoin/BuyCoin';
import { useNavigate } from 'react-router-dom';

function Pump() {
    
    const { t, i18n } = useTranslation();
    const [potential, setPotential] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [inputValue, setInputValue] = useState(1);
    const [show, setShow] = useState(false); 
    const [showPopup, setShowPopup] = useState(false); 
    const tonAddress = useTonAddress();
    const navigate = useNavigate()
    const getPumpTasksData = async () => {
        const data = await getPumpTasks(); 
        setTasks(data.tasks);
    };
    
    const getPotentialUsdtSectionData = async () => {
        if (tonAddress.length > 0) {
            const data = await getPotentialUsdtSection(tonAddress);
            setPotential(data);
        }
    };

    const formatAddress = (address) => {
        if (!address) return t('pump.connectWallet');
        return `${address.slice(0, 6)}....${address.slice(-4)}`;
    };

    const handleInputChange = (event) => {
        const value = Math.min(Math.max(parseInt(event.target.value), 1), 1000);
        setInputValue(value);
    };
    
    const handlePopupOpen = () => {
        setShowPopup(true);
    };
    
    const handlePopupClose = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        getPotentialUsdtSectionData();
        getPumpTasksData();
    }, [tonAddress]);

    function getColorByValue(value) {
        if (value >= 10 && value < 20) {
            return "#9e3817"; 
        } else if (value >= 20 && value < 40) {
            return "#c95b1d"; 
        } else if (value >= 40 && value < 60) {
            return "#e3b71d"; 
        } else if (value >= 60 && value < 80) {
            return "#b1e31d"; 
        } else if (value >= 80 && value <= 100) {
            return "#2ce31d"; 
        } else {
            return "#2ce31d"; 
        }
    }

    const promoUrl = (i18n.language === 'ru' || i18n.language === 'uk')
        ? "https://www.youtube.com/watch?v=AkCyHOUfjjY&t=1s"
        : "https://www.youtube.com/watch?v=sQH0y823W6U&t=186s";

    return (
        <div className='height100'>
            <Back />
            {show ? <BuyCoin setShow={setShow}/>
            
            :
            <>
                <div className='pump_top_container'>
                <div className='pump_title'>{t('pump.pumpTitle')}</div>
                <img src={fon} className='background_pump' alt="Background"></img>
            </div>

            <div className='outer-container_pump'>
                <div className='container_glow_pump'>


                    <div className="promo-container-buy" onClick={()=>navigate("/listing")}>
                        <p className="promo-text">BUY $NIKI COIN</p>
                        <img style={{height:"50px"}} src={visa}/>
                        <div className="promo-button_buy" target="_blank" rel="noopener noreferrer" >
                            Buy
                            <img src={tether} className='icon1_small'/>
                        </div>
                    </div>

                    <div className="promo-container">
                        <p className="promo-text">{t('promo.promoText')}</p>
                        <a href={promoUrl} className="promo-button" target="_blank" rel="noopener noreferrer">
                            {t('promo.promoButton')}
                            <img className="arrow-icon" src={pointer} alt="Иконка стрелки" />
                        </a>
                    </div>
                    

                    {tonAddress.length > 0 ? 
                    <>
                        <div className='pump_info_container'>
                            <div className="pampu-block">
                                <div className="block-content">
                                    <div className="usdt-you">
                                        {potential.wallet_balance < 1 ? (
                                            <>
                                                <img src={finger} className='finger'/>
                                                <input 
                                                    type="number" 
                                                    min="1" 
                                                    max="10" 
                                                    value={inputValue} 
                                                    onChange={handleInputChange} 
                                                    className="input-value usdt-amount" 
                                                />
                                                <p className="usdt-label"><img className='wallet' src={wallet}/>{t('pump.usdtYou')}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="usdt-amount">{formatNumber(potential.wallet_balance)}</p>
                                                <p className="usdt-label">{t('pump.usdtYou')}</p>
                                            </>
                                        )}
                                    </div>

                                    <div className="arrow-pump">
                                        <span>→</span>
                                    </div>

                                    <div className="usdt-potential" onClick={handlePopupOpen}>
                                        <p className="potential-amount">
                                            {potential.wallet_balance < 1 ? formatNumber(inputValue * potential.coefficient) : formatNumber(potential.potentional_wallet_balance)}
                                        </p>
                                        <p className="potential-label">{t('pump.usdtPotential')} <i>ⓘ</i></p>
                                    </div>
                                </div>
                                {potential.percentage_of_completed_tasks>=100?
                                    <div className="ready-section">
                                        <div className='percent_container'>
                                            <div className="progress_bar_pump_honey">
                                                <div 
                                                    className="progress_league" 
                                                    style={{ width: `${potential.percentage_of_completed_tasks}%`, background: getColorByValue(potential.percentage_of_completed_tasks) }}
                                                ></div>
                                                <div className='percent_ready'>{potential.percentage_of_completed_tasks}%</div>
                                            </div>
                                        </div>
                                        <p className="ready-text">{t('pump.ready')} <span className='lime'>$NIKI PUMP!</span></p>
                                    </div>
                                    :
                                    <div className="ready-section">
                                        <div className='percent_container'>
                                            <p className="status-text">{t('pump.statusText')}</p>
                                            <div className="progress_bar_pump">
                                                <div 
                                                    className="progress_league" 
                                                    style={{ width: `${potential.percentage_of_completed_tasks}%`, background: getColorByValue(potential.percentage_of_completed_tasks) }}
                                                ></div>
                                                <div className='percent_ready'>{potential.percentage_of_completed_tasks}%</div>
                                            </div>
                                        </div>
                                        <p className="ready-text">{t('pump.readyText')}</p>
                                    </div>
                                }
                                
                            </div>
                        </div>
                        {potential.percentage_of_completed_tasks >= 100 ? (

                            <>
                            <div className='ready_text'>{t('pump.waiting')}</div>
                            <div className='container_letsgo'>
                                <img className="icon2" src={icon2} />
                                <div className='button_letsgo' onClick={()=>openLink("https://t.me/nikicoineth_bot")}>
                                    {t('pump.letsgo')}
                                    <img className="icon1" src={icon1} />
                                </div>
                            </div>
                            </>
                           
                        ) : (
                            tasks.map((task, index) => (
                                <EarningsComponent 
                                    key={index}
                                    task={task}  
                                    getPumpTasksData={getPumpTasksData}
                                    getPotentialUsdtSectionData ={getPotentialUsdtSectionData}
                                />
                            ))
                        )}
                        
                    </>
                    :
                        <button className="settings-item button_connect">
                            <img src={finger} className='finger'/>
                            <div className="settings-text">
                                <div className="settings-title">
                                    {formatAddress(tonAddress)}
                                </div>
                            </div>
                            <img className="settings-icon" src={wallet} alt="wallet icon" />
                            <TonConnectButton
                                style={{ background: "none", color: "inherit" }}
                            ></TonConnectButton> 
                        </button>
                    }
                </div>
            </div>

            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p className="popup-warning">{t('pump.popupWarning')}</p>
                        <p>{t('pump.popupText1')}</p>
                        <p>{t('pump.popupText2')}</p>
                        <button className="popup-button" onClick={handlePopupClose}>OK</button>
                    </div>
                </div>
            )}
            </>

            }
            
        </div>
    );
}

export default Pump;
