import { completeTask } from '../../http';
import coinImg from '../../images/icons/coin.png';
import Back from '../Back';
import Task from './Task';
import "./tasks.scss";
import test from "./test.png";
import React, { useState, useEffect } from 'react';
import StatBlock from '../StatsMain/StatBlock';
import { useAppData } from '../../AppContext';
import PetCard from './PetCard';
import { useLocation } from 'react-router-dom';
import { getPlayerMemes, manageMeme, seeTask, getPlayerLocationtasks, getUserPromotions, getUserExtraIncome, manageExtraIncome, getPlayerTaskMemes, manageTaskMeme, seeTaskMeme } from '../../http';
import { completePromotion, seePromotion, SendInvite } from '../../http';
import { useTranslation } from 'react-i18next';
import Promotion from './Promotion';
import { openLink } from '../../Utils/telegramUtils';
import logo from "./1.png"
import NotEnoughMoney from '../Pet/MainPet/NotEnoughMoney/NotEnoughMoney';
import Meme from './Meme';
import InviteTask from './InviteTask';
import PromotionBig from './PromotionBig';
export const Tasks = () => {
    const { t } = useTranslation();
    const { coins, Pet, memes, handleEditCoins, setpassiveIncomePerHour, passiveIncomePerHour, refferedCount } = useAppData();
    const [activeTab, setActiveTab] = useState('tasks');
    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [palyerMemes, setpalyerMemes] = useState(memes);

    const [playerTaskMemes, setplayerTaskMemes] = useState([]);
    const [playerTaskInvite, setplayerTaskInvite] = useState([]);
    const [promotion, setPromotion] = useState([]);
    const [ExtraIncome, setExtraIncome] = useState([]);

    const [selectedPromotion, setselectedPromotion] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedTaskMeme, setSelectedTaskMeme] = useState(null);
    const [selectedExtraIncome, setselectedExtraIncome] = useState(null);
    const [selectedMeme, setSelectedMeme] = useState(null);


    const location = useLocation();
    const { tasks, setTriggerRender } = useAppData();

    useEffect(() => {
        if (location.pathname.includes('/earn/tasks')) {
            setActiveTab('tasks');
        } else if (location.pathname.includes('/earn/income')) {
            setActiveTab('income');
        } else if (location.pathname.includes('/earn/craft')) {
            setActiveTab('craft');
        }
    }, [location]);

    useEffect(() => {
        getUserPromotiosData();
        getPlayerMemesData();
        getUserExtraIncomeData();
        getPlayerTasksMemesData();
        getPlayerLocationtasksData();

    }, []);

    const handleCloseModal = () => {
        setSelectedTask(null);
        setSelectedMeme(null);
        setselectedPromotion(null);
        setselectedExtraIncome(null);
        setSelectedTaskMeme(null)
    };

    const forceUpdate = () => setTriggerRender(prev => !prev);

    const renderDescription = (description) => {
        const lines = description.split('+').filter(line => line.trim() !== '');
        return lines.map((line, index) => (
            <p key={index}>+ {line.trim()}</p>
        ));
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const getUserExtraIncomeData = async () => {
        const data = await getUserExtraIncome();

        setExtraIncome(data.extra_incomes)

    };
    const getUserPromotiosData = async () => {
        const data = await getUserPromotions();

        setPromotion(data.promotions);
    };
    const getPlayerLocationtasksData = async () => {
        const data = await getPlayerLocationtasks();

        setplayerTaskInvite(data.tasks);
    };

    const getPlayerMemesData = async () => {
        const data = await getPlayerMemes();
        setpalyerMemes(data.memes);
    };
    const getPlayerTasksMemesData = async () => {
        const data = await getPlayerTaskMemes();
        setplayerTaskMemes(data.task_memes)
    };



    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'K';
        }
        return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };


    const handleMoadalTaskClick = async (link, id) => {
        try {
            await seeTask(id);
            openLink(link)
            handleCloseModal();
            forceUpdate();

        } catch (error) {
            console.error("Failed to complete task", error);
        }
    };
    const handleTaskClick = (task) => {
        setSelectedTask(task);
    };
    const handleCompleteTask = async (id, task__coins_reward) => {
        try {
            await completeTask(id);
            handleEditCoins(task__coins_reward);
            handleCloseModal();
            forceUpdate();
        } catch (error) {
            console.error("Failed to complete task", error);
        }
    };

    //PROMOTIONS
    const handlePromotionClick = (promotion) => {
        setselectedPromotion(promotion);
        console.log("123123213")
    };
    const handleModalPromotionClick = async (link, id) => {
        try {
            await seePromotion(id);
            openLink(link)
            handleCloseModal();
            forceUpdate();
            getUserPromotiosData()


        } catch (error) {
            console.error("Failed to complete task", error);
        }
    };
    const handleComplePromotion = async (id, coins) => {
        try {
            await completePromotion(id);
            handleEditCoins(coins);
            handleCloseModal();
            getUserPromotiosData()
        } catch (error) {
            console.error("Failed to complete task", error);
        }
    };


    //MEMES
    const handleMemeClick = (memeItem) => {
        setSelectedMeme(memeItem);
    };
    const handleManageMeme = async (memeId, coins_per_hour, upgrade_cost, upgraded_coins_per_hour) => {
        if (coins >= upgrade_cost) {
            await manageMeme(memeId);
            handleEditCoins(-upgrade_cost);
            setpassiveIncomePerHour(prev => prev + (upgraded_coins_per_hour - coins_per_hour));
            await getPlayerMemesData();
        }
        else {
            setShowError(true)
        }
        handleCloseModal();
    };
    const handleMemeTaskClick = (memeItem) => {
        setSelectedTaskMeme(memeItem);
        console.log(selectedTaskMeme)
    };

    const handleManageTaskMeme = async (memeId, coins_per_hour, upgrade_cost, upgraded_coins_per_hour) => {
        if (coins >= upgrade_cost) {
            await manageTaskMeme(memeId);
            handleEditCoins(-upgrade_cost);
            setpassiveIncomePerHour(prev => prev + (upgraded_coins_per_hour - coins_per_hour));
            await getPlayerTasksMemesData();
        }
        else {
            setShowError(true)
        }
        handleCloseModal();
    };

    //EXTRA INCOME 
    const handleExtraClick = (memeItem) => {
        setselectedExtraIncome(memeItem);
    };

    const handleManageExtraIncome = async (extra_income_id, coins_per_hour, upgrade_cost, upgraded_coins_per_hour) => {
        if (coins >= upgrade_cost) {
            await manageExtraIncome(extra_income_id);
            handleEditCoins(-upgrade_cost);
            setpassiveIncomePerHour(prev => prev + (upgraded_coins_per_hour - coins_per_hour));
            await getUserExtraIncomeData();
        }
        else {
            setShowError(true)
        }
        handleCloseModal();
    };

    const handleClick = () => {
        SendInvite();
        window.Telegram.WebApp.close();
    };

    const handleMoadalMemeTaskClick = async (link, id) => {
        try {
            await seeTaskMeme(id);
            // openLink(link)
            handleCloseModal();
            forceUpdate();

        } catch (error) {
            console.error("Failed to complete task", error);
        }
    };
    const isSubscribeLocked = async (type, is_seen) => {
        console.log(type === "subscribe" && is_seen)
        if (type === "subscribe") {
            return is_seen;
        }
        return true;
    };


    return (
        <div className='outer-container_boost'>
            <div className='container_glow_shop'>

                {showError ? <NotEnoughMoney /> : ""}
                <Back />

                <StatBlock />
                <div className='coins_container_shop'>
                    <div className='coins_text'>{t('taskspage.yourBalance')}</div>
                    <div className="stats">
                        <div id="balance" className="coin-count flex_center">
                            <img src="https://nikicoin.store/media/images/coin.png" className="coin_balance_shop" alt="Coin-icon" /> {coins.toLocaleString('en-US')}
                        </div>
                    </div>
                </div>

                <div className="navigationTab">
                    <button className={`navButton_task ${activeTab === 'tasks' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('tasks')}>
                        <span>{t('taskspage.tasks')}</span>
                    </button>

                    <button className={`navButton_task ${activeTab === 'promotion' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('promotion')}>
                        <span>{t('taskspage.Promotion')}</span>
                    </button>

                    <button className={`navButton_task ${activeTab === 'income' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('income')}>
                        <span>{t('taskspage.income')}</span>
                    </button>

                    <button className={`navButton_task ${activeTab === 'extraIncome' ? 'selected' : ''}`}
                        onClick={() => { setShow(true); setActiveTab("extraIncome"); }}>
                        <span className='craft_container'>
                            <span>{t('taskspage.extraIncome')}</span>
                            {refferedCount < 3 ? <span className='coming_soon_text'>{t('taskspage.addFriends')}</span> : ""}
                        </span>
                    </button>

                </div>

                <div className='scroll'>
                    <div className='shop_grid'>
                        {activeTab === 'tasks' && tasks.map((task) => (
                            <Task
                                key={task.id}
                                task={task.task__name}
                                icon={task.task__logo || test}
                                coins={task.task__coins_reward}
                                status={task.status}
                                onClick={() => handleTaskClick(task)}
                                isSeen={task.is_seen}
                                link={task.task__link}

                            />
                        ))}
                        {activeTab === 'tasks' && playerTaskInvite.map((task) => (
                            <InviteTask
                                key={task.id}
                                task={task.location_task__name}
                                icon={task.location_task__logo}
                                coins={task.location_task__coins_reward}
                                status={task.status}
                                onClick={() => handleTaskClick(task)}
                                isSeen={task.is_seen}
                                link={task.link}
                            />
                        ))}

                        {activeTab === 'extraIncome' && palyerMemes.map((meme) => (
                            <Meme
                                key={meme.meme_id}
                                name={meme.name}
                                logo={meme.logo}
                                upgradeCost={meme.upgrade_cost}
                                coins_per_hour={meme.coins_per_hour}
                                level={meme.level}
                                is_seen={meme.is_seen}
                                onMemeClick={() => handleMemeClick(meme)}
                            />
                        ))}

                        {activeTab === 'income' && playerTaskMemes.map((meme) => (
                            <PetCard
                                key={meme.task_meme_id}
                                name={meme.name}
                                logo={meme.logo}
                                coins_per_hour={meme.coins_per_hour}
                                level={meme.level}
                                upgradeCost={meme.upgrade_cost}
                                additional_friends={meme.additional_friends}
                                onMemeClick={() => handleMemeTaskClick(meme)}
                                type={meme.type}
                                is_seen={meme.is_seen}
                                link={meme.link}
                                next_coins_per_hour={meme.next_coins_per_hour}
                            />
                        ))}
                        

                        {activeTab == 'promotion' ?
                            <PromotionBig
                                key={10}
                                coins={10000000}
                                title={promotion.promotion__title}
                                background={logo}
                                coming={true}
                                doble={true}
                            /> : null
                        }

                        {activeTab === 'promotion' && promotion.map((promotion) => (
                            <Promotion
                                key={promotion.id}
                                onClick={() => handlePromotionClick(promotion)}
                                coins={promotion.promotion__coins_reward}
                                title={promotion.promotion__title}
                                text={promotion.promotion__name}
                                subtext={promotion.promotion__description}
                                link={promotion.promotion__link}
                                logo={promotion.promotion__logo}
                                background={promotion.promotion__background}
                                status={promotion.status}
                                coming={promotion.promotion__is_coming_soon}
                            />
                        ))
                        }


                       
                    </div>

                    <div className='shop_grid'>
                        {activeTab === 'extraIncome' && ExtraIncome.map((meme) => (
                            <Meme
                                key={meme.meme_id}
                                name={meme.name}
                                logo={meme.logo}
                                upgradeCost={meme.upgrade_cost}
                                coins_per_hour={meme.coins_per_hour}
                                level={meme.level}
                                onMemeClick={() => handleExtraClick(meme)}
                            />
                        ))}
                        {activeTab === 'extraIncome' && refferedCount < 3 ? <div className='blocked_tasks'>Invite 3 friends to unlock
                            <div className='invite_task' onClick={() => handleClick()}>Unlock</div>
                        </div> : ""}
                    </div>

                </div>

            </div>

            {selectedTask && (
                <div className="modal">
                    <div className="modal-container-tasks">
                        <span className="close" onClick={handleCloseModal}>&times;</span>
                        <div className="modal-content_tasks">
                            <img src={selectedTask.task__logo || test} className="modal-image" alt="Task icon" />
                            <h2 className='modal-title'>{selectedTask.task__name}</h2>
                            <div className='task__description'>
                                {renderDescription(selectedTask.task__description)}
                            </div>
                            <p style={{ fontWeight: 'bold' }}>+ {selectedTask.task__coins_reward} <img src={coinImg} className="coin-icon" alt="Coin-icon" /></p>
                            <div className='penalty'>{selectedTask.task__penalty} </div>
                            <button className='button-open-task button-purple modal-button' onClick={() => handleMoadalTaskClick(selectedTask.task__link, selectedTask.id, selectedTask.task__coins_reward)}>{t('taskspage.openTask')}</button>
                            {selectedTask.is_seen ?
                                <button button className='button-open-task button-orange modal-button check_task' onClick={() => handleCompleteTask(selectedTask.id, selectedTask.task__coins_reward)}>
                                    {t('taskspage.checkTask')}
                                </button>
                                : ""}
                        </div>
                    </div>
                </div>
            )
            }

            {selectedPromotion && (
                <div className="modal">
                    <div className="modal-container-tasks">
                        <span className="close" onClick={handleCloseModal}>&times;</span>
                        <div className="modal-content_tasks">
                            <img src={selectedPromotion.promotion__logo || test} className="modal-image" alt="Task icon" />
                            <h2 className='modal-title'>{selectedPromotion.promotion__name}</h2>
                            <div className='task__description'>
                                {renderDescription(selectedPromotion.promotion__description)}
                            </div>
                            <p style={{ fontWeight: 'bold' }}>{selectedPromotion.promotion__coins_reward} <img src={coinImg} className="coin-icon" alt="Coin-icon" /></p>
                            <div className='penalty'>{selectedPromotion.promotion__penalty} </div>
                            <button className='button-open-task button-purple modal-button' onClick={() => handleModalPromotionClick(selectedPromotion.promotion__link, selectedPromotion.id)}>{t('taskspage.openTask')}</button>
                            {selectedPromotion.is_seen ?
                                <button button className='button-open-task button-orange modal-button check_task' onClick={() => handleComplePromotion(selectedPromotion.id, selectedPromotion.promotion__coins_reward)}>
                                    {t('taskspage.checkTask')}
                                </button>
                                : ""}
                        </div>
                    </div>
                </div>
            )
            }

            {selectedExtraIncome && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container-tasks">
                            <div className="modal_content_tasks">
                                <span className="close" onClick={handleCloseModal}>&times;</span>
                                <img src={selectedExtraIncome.logo} alt={selectedExtraIncome.name} className="modal-image_shop" />
                                <h3>{selectedExtraIncome.name}</h3>
                                {selectedExtraIncome.is_owned == "true" ? (
                                    <div>
                                        <div className='text-modal'>{t('taskspage.youOwnThisMeme')}</div>
                                    </div>
                                ) : (
                                    <div style={{ width: "100%" }}>
                                        <div className='text-modal-level'>{t('taskspage.level')} {selectedExtraIncome.level} {'->'} <span className='green'>{selectedExtraIncome.level + 1}</span></div>
                                        <div className='text-modal'>{t('taskspage.profitPerHour')}</div>
                                        <div className='text-modal-profit '>
                                            <img src="https://nikicoin.store/media/images/coin.png"
                                                className="coin_icon_shop_modal"
                                                alt="Coin-icon" />
                                            {passiveIncomePerHour}
                                            {'->'} +<span className='green'>{passiveIncomePerHour + (selectedExtraIncome.next_coins_per_hour - selectedExtraIncome.coins_per_hour)}</span>
                                            <img src="https://nikicoin.store/media/images/coin.png"
                                                className="coin_icon_shop_modal"
                                                alt="Coin-icon" />
                                        </div>
                                        <div className='coins_modal_shop'><img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop_modal_total" alt="Coin-icon" />{formatNumber(selectedExtraIncome.upgrade_cost)} </div>
                                        <button className='button_upgrade' onClick={() => handleManageExtraIncome(selectedExtraIncome.extra_income_id, selectedExtraIncome.coins_per_hour, selectedExtraIncome.upgrade_cost, selectedExtraIncome.next_coins_per_hour)} >{t('taskspage.buy')}</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )

            }
            {selectedMeme && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container-tasks">
                            <div className="modal_content_tasks">
                                <span className="close" onClick={handleCloseModal}>&times;</span>
                                <img src={selectedMeme.logo} alt={selectedMeme.name} className="modal-image_shop" />
                                <h3>{selectedMeme.name}</h3>
                                {selectedMeme.is_owned == "true" ? (
                                    <div>
                                        <div className='text-modal'>{t('taskspage.youOwnThisMeme')}</div>
                                    </div>
                                ) : (
                                    <div style={{ width: "100%" }}>
                                        <div className='text-modal-level'>{t('taskspage.level')} {selectedMeme.level} {'->'} <span className='green'>{selectedMeme.level + 1}</span></div>
                                        <div className='text-modal'>{t('taskspage.profitPerHour')}</div>
                                        <div className='text-modal-profit '>
                                            <img src="https://nikicoin.store/media/images/coin.png"
                                                className="coin_icon_shop_modal"
                                                alt="Coin-icon" />
                                            {passiveIncomePerHour}
                                            {'->'} +<span className='green'>{passiveIncomePerHour + (selectedMeme.next_coins_per_hour - selectedMeme.coins_per_hour)}</span>
                                            <img src="https://nikicoin.store/media/images/coin.png"
                                                className="coin_icon_shop_modal"
                                                alt="Coin-icon" />
                                        </div>
                                        <div className='coins_modal_shop'><img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop_modal_total" alt="Coin-icon" />{formatNumber(selectedMeme.upgrade_cost)} </div>
                                        <button className='button_upgrade' onClick={() => handleManageMeme(selectedMeme.meme_id, selectedMeme.coins_per_hour, selectedMeme.upgrade_cost, selectedMeme.next_coins_per_hour)} >{t('taskspage.buy')}</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

            {selectedTaskMeme && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container-tasks">
                            <div className="modal_content_tasks">
                                <span className="close" onClick={handleCloseModal}>&times;</span>

                                {selectedTaskMeme.additional_friends <= 0 && isSubscribeLocked(selectedTaskMeme.type, selectedTaskMeme.is_seen) ? (
                                    <>
                                        <img className='max_height' src={selectedTaskMeme.logo_big} alt={selectedTaskMeme.name} />
                                        <h3>{selectedTaskMeme.name}</h3>

                                        <div style={{ width: "100%" }}>
                                            <div className='text-modal-level'>{t('taskspage.level')} {selectedTaskMeme.level} {'->'} <span className='green'>{selectedTaskMeme.level + 1}</span></div>
                                            <div className='text-modal'>{t('taskspage.profitPerHour')}</div>
                                            <div className='text-modal-profit '>
                                                <img src="https://nikicoin.store/media/images/coin.png"
                                                    className="coin_icon_shop_modal"
                                                    alt="Coin-icon" />
                                                {passiveIncomePerHour}
                                                {'->'} +<span className='green'>{passiveIncomePerHour + (selectedTaskMeme.next_coins_per_hour - selectedTaskMeme.coins_per_hour)}</span>
                                                <img src="https://nikicoin.store/media/images/coin.png"
                                                    className="coin_icon_shop_modal"
                                                    alt="Coin-icon" />
                                            </div>
                                            <div className='coins_modal_shop'>
                                                <img src="https://nikicoin.store/media/images/coin.png" className="coin_icon_shop_modal_total" alt="Coin-icon" />
                                                {formatNumber(selectedTaskMeme.upgrade_cost)}
                                            </div>
                                            {selectedTaskMeme.type === "friends" && selectedTaskMeme.additional_friends > 0 ? (
                                                <div className="locked">
                                                    🔒 Invite {selectedTaskMeme.additional_friends} more friends
                                                </div>
                                            ) : (
                                                <button className='button_upgrade' onClick={() => handleManageTaskMeme(selectedTaskMeme.task_meme_id, selectedTaskMeme.coins_per_hour, selectedTaskMeme.upgrade_cost, selectedTaskMeme.next_coins_per_hour)}>{t('taskspage.buy')}</button>
                                            )}
                                        </div>
                                    </>
                                ) : isSubscribeLocked(selectedTaskMeme.type, selectedTaskMeme.is_seen) ? (
                                    <>

                                        <img src={selectedTaskMeme.logo} alt={selectedTaskMeme.name} className="img_blocked" />

                                        <div className='flex_info'>

                                            <h3>{selectedTaskMeme.name}</h3>

                                            <div className='invite_task' onClick={() => handleMoadalMemeTaskClick(selectedTaskMeme.link, selectedTaskMeme.task_meme_id)}>Unlock</div>

                                        </div>



                                    </>
                                ) : (
                                    <>

                                        <img src={selectedTaskMeme.logo} alt={selectedTaskMeme.name} className="img_blocked" />
                                        <div className='flex_info'>
                                            <h3>{selectedTaskMeme.name}</h3>
                                            <div className="locked">
                                                🔒 Invite {selectedTaskMeme.additional_friends} more friends
                                            </div>
                                            <div className='invite_task' onClick={() => handleClick()}>Unlock</div>
                                        </div>

                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            )}




        </div >
    );
};
