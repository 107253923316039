import React,{useEffect, useState} from 'react';
import "./tasks.scss";
import { openLink } from '../../Utils/telegramUtils';
import change from "./change.png"

function Countdown({ endDate }) {
    
    const calculateTimeLeft = () => {
        const difference = +new Date(endDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div className="countdown_listing_promotion">
            {Object.keys(timeLeft).length > 0 ? (
                Object.keys(timeLeft).map((interval) => (
                    <div key={interval} className="countdown-item_exchange">
                        <div className="countdown_time_promotion">{timeLeft[interval]}</div>
                        <div className="countdown_label_promotion">{interval}</div>
                    </div>
                ))
            ) : (
                <></>
            )}
        </div>
    );
}

function PromotionBig({ coins, onClick, logo, status, link, background, coming, doble }) {
    return (
        <div
            className={"promotionTab " + (doble ? " doble_promotion " : "") + (status === "CM" ? " completed" : "") + (coming ? " coming_soon" : "")}
            onClick={status === "CM" ? () => openLink(link) : onClick}
            style={doble ? { height: '230px' } : {}}
        >
            <div className='promotion_background' style={{ backgroundImage: `url(${background})`, ...(doble ? { opacity: '0.8' } : {}) }}></div>
            <div className='promotion_content dex'>
                {
                    !logo ? null : <img src={logo} className='promotion_img' />
                }

                {status === "CM" ? (
                    <svg width="20" height="20" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.136364 19.4773L6.54545 13.0455L17.0682 23.3864L40.4091 0.136364L46.8636 6.56818L17.0682 36.2045L0.136364 19.4773Z" fill="white" />
                    </svg>

                ) : (
                    <div className='row_promotion '>
                        <div>+{coins.toLocaleString('en-US')}</div>
                        <img className='coin_img' src="https://nikicoin.store/media/images/coin.png" alt="coin" />
                    </div>
                )}
            </div>
            {/* <Countdown endDate={"2024-12-19T11:00:00"} /> */}
            <img className='image_change_tasks' src={change}/>

        </div >
    );
}


export default PromotionBig;
